import React from 'react'
import Helmet from 'react-helmet'
import {graphql} from 'gatsby'
import Layout from '../components/layout'

export const query = graphql`
query (
    $slug: String!
) {
    markdownRemark (
        fields: {
            slug: {
                eq: $slug
            }
        }
    ) {
        frontmatter {
            title
        }
        html
    }
}
`
const BlogTemplate = (props) => {
    return (
        <Layout>
            <Helmet
                 title=" خدمات مهاجرتی فرزان - بلاگ  "
                meta={[
                    { name: 'description', content: ' دکتر فرزان سجودی وکیل با سابقه مهاجرت، همراه شما در مهاجرت به کانادا  ' }
                    
                ]}
            >
            </Helmet>

            <div className="blog-template">
                <div id="one">
                    <header className="inner">
                        <h3>{props.data.markdownRemark.frontmatter.title}</h3>
                        <div dangerouslySetInnerHTML={{ __html: props.data.markdownRemark.html }} />    
                    </header>
                </div>
            </div>

        </Layout>
    )
}

export default BlogTemplate